<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'
import { SweetModal } from 'sweet-modal-vue'
import PaginationCustom from '@/components/table/Pagination.vue'
import mixinHelper from '@/mixins/helper'

export default {
   name: 'Lkpm',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
      SweetModal,
      VuePdfApp,
   },
   mixins: [mixinHelper],
   data() {
      return {
         item: {},
         mode: 'INSERT',
         pdf: '',
         loadingPdf: false,
         nilai: 0,
         form: {
            nomor: null,
            nama: null,
            proyek: null,
            triwulan: null,
            tahun: null,
            status: null,
            tanggal: null,
            jenis: null,
            nilai: 0,
         },
         filter: {
            tahun: null,
            triwulan: null,
            status: null,
            jenis: null,
         },
         required,
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nomor',
               field: 'nomor',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
               },
            },
            {
               label: 'Nama Perusahaan',
               field: 'nama',
               sortable: false,
               thClass: 'text-center align-middle',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari',
                  filterValue: '',
               },
            },
            {
               label: 'Triwulan / Tahun',
               field: 'triwulan',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center align-middle',

            },
            {
               label: 'Nilai',
               field: 'nilai',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         options: {
            jenis: [
               { value: null, text: '-' },
               { value: 'KONSTRUKSI', text: 'KONSTRUKSI' },
               { value: 'PRODUKSI', text: 'PRODUKSI' },
            ],
            triwulan: [
               { value: null, text: '-' },
               { value: 'I', text: 'I' },
               { value: 'II', text: 'II' },
               { value: 'III', text: 'III' },
               { value: 'IV', text: 'IV' },
            ],
            status: [
               { value: null, text: '-' },
               { value: 'Disetujui', text: 'Disetujui' },
               { value: 'Terkirim ke BKPM', text: 'Terkirim ke BKPM' },
               { value: 'Terkirim ke BKPM (LKPM Daerah)', text: 'Terkirim ke BKPM (LKPM Daerah)' },
               { value: 'Perlu Perbaikan', text: 'Perlu Perbaikan' },
               { value: 'Sudah Diperbaiki', text: 'Sudah Diperbaiki' },
            ],
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.Lkpm.items,
         myTotal: state => state.Lkpm.total,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
   },
   watch: {
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      nilai: function _(newVal) {
         if (typeof newVal === 'string') {
            this.form.nilai = this.convertNumberOnly(newVal)
         }
      },
      'form.tahun': function _(newVal) {
         this.form.tahun = this.convertDate2Year(newVal)
      },
      'filter.tahun': function _(newVal) {
         this.filter.tahun = this.convertDate2Year(newVal)
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'Lkpm/getData',
         getItem: 'Lkpm/getItem',
         postItem: 'Lkpm/postItem',
         updateItem: 'Lkpm/updateItem',
         deleteItem: 'Lkpm/deleteItem',
         cetakNib: 'Lkpm/cetakNib',
      }),
      onCetakNib(id) {
         this.loadingPdf = true
         this.cetakNib(id).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onAdd() {
         this.resetForm()
         this.$bvModal.show('modal-form')
      },
      onEdit(id) {
         this.getItem(id).then(item => {
            this.nilai = item.nilai
            this.form = item
            this.mode = 'UPDATE'
            this.$bvModal.show('modal-form')
         })
      },
      onDelete(id) {
         this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data tidak dapat dipulihkan kembali!',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ya, Hapus!',
            html: false,
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-danger',
               cancelButton: 'btn btn-outline-dark ml-1',
            },
            buttonsStyling: false,
            preConfirm: () => this.deleteItem(id).then(() => {
               this.loadItems()
               this.$swal('Deleted!', '', 'success')
            }).catch(() => {
               this.$swal('Gagal!', '', 'error')
            }),
         })
      },
      onSave() {
         this.$refs.myForm.validate().then(success => {
            console.log(success)
            if (success) {
               if (this.mode === 'INSERT') {
                  this.postItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               } else if (this.mode === 'UPDATE') {
                  this.updateItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               }
            }
         })
      },
      resetForm() {
         this.form = {
            nomor: null,
            nama: null,
            proyek: null,
            triwulan: null,
            tahun: null,
            status: null,
            tanggal: null,
            jenis: null,
            nilai: 0,
         }
         this.nilai = 0
         this.mode = 'INSERT'
      },
      onDetail(id) {
         this.item = this.myData.find(item => item.id === id)
         this.$bvModal.show('modal-detail')
      },
      onFilter() {
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      onRefresh() {
         this.filter = {
            tahun: null,
            triwulan: null,
            status: null,
            jenis: null,
         }
         setTimeout(() => {
            this.loadItems()
         }, 100)
      },
      // VUE GOOD TABLE
      loadItems() {
         this.getData(this.filter)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data LKPM">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <b-form-group label="Realisasi" label-for="realisasi">
                     <b-input-group prepend="Rp.">
                        <b-form-input
                           id="realisasi"
                           class="text-right"
                           :value="myTotal | formatNumber"
                           focused
                        />
                     </b-input-group>
                  </b-form-group>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1 align-self-end">
                     <b-button
                        v-b-tooltip.hover.top="'Tambah'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onAdd()"
                     >
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Filter'"
                        v-b-toggle.collapse-filter
                        variant="outline-secondary"
                        size="sm"
                     >
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Refresh & Reset'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onRefresh()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAdd()">
                           Tambah
                        </b-dropdown-item>
                        <b-dropdown-item v-b-toggle.collapse-filter>
                           Filter
                        </b-dropdown-item>
                        <b-dropdown-item @click="onRefresh()">
                           Refresh & Reset
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
            <!-- FILTER -->
            <b-col>
               <b-collapse id="collapse-filter" class="mt-2">
                  <b-card class="border">
                     <b-row>
                        <b-col md="3">
                           <b-form-group label="Tahun" label-for="tahun">
                              <datepicker
                                 v-model="filter.tahun"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 input-class="form-control"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Jenis" label-for="jenis">
                              <b-form-select
                                 v-model="filter.jenis"
                                 :options="options.jenis"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="status" label-for="status">
                              <b-form-select
                                 v-model="filter.status"
                                 :options="options.status"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Triwulan" label-for="triwulan">
                              <b-form-select
                                 v-model="filter.triwulan"
                                 :options="options.triwulan"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                     </b-row>
                  </b-card>
               </b-collapse>
            </b-col>
         </b-row>

         <form autocomplete="off">
            <!-- table -->
            <vue-good-table
               ref="goodTable"
               :columns="columns"
               :rows="myData"
               :line-numbers="true"
               :pagination-options="{
                  enabled: true,
                  mode: 'pages',
               }"
               :search-options="{
                  enabled: false,
                  externalQuery: searchTerm,
               }"
            >
               <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'nomor'">
                     <b>{{ props.row.nomor }}</b>
                     <hr>
                     <small>{{ props.row.tanggal | moment("DD MMMM YYYY") }}</small>
                     <br>
                     <b>{{ props.row.id }}</b>
                  </span>
                  <span v-if="props.column.field == 'nama'">
                     <b>{{ props.row.nama }}</b>
                     <hr>
                     <small>Proyek : {{ props.row.proyek }}</small>
                  </span>
                  <span v-if="props.column.field == 'triwulan'">
                     Triwulan :
                     <b>{{ props.row.triwulan }}</b>
                     <hr>Tahun :
                     <b>{{ props.row.tahun }}</b>
                     <hr>Jenis :
                     <small>
                        <b>{{ props.row.jenis }}</b>
                     </small>
                  </span>
                  <span v-if="props.column.field == 'nilai'">
                     <span class="float-right">
                        <b>{{ props.row.nilai | formatNumber }}</b>
                     </span>
                  </span>

                  <span v-if="props.column.field == 'status'">
                     <center>
                        <b-badge variant="secondary" class="text-white">
                           <small>{{ props.row.status }}</small>
                        </b-badge>
                     </center>
                  </span>

                  <span v-if="props.column.field == 'action'">
                     <center>
                        <b-button-group>
                           <b-button
                              v-b-tooltip.hover.top="'Cetak Laporan'"
                              size="sm"
                              variant="outline-primary"
                              :disabled="!props.row.filename"
                              class="nowrap"
                              @click="onCetakNib(props.row.id)"
                           >
                              <feather-icon icon="FileTextIcon" />
                           </b-button>
                           <b-button
                              v-b-tooltip.hover.top="'Detail'"
                              variant="outline-dark"
                              size="sm"
                              @click="onDetail(props.row.id)"
                           >
                              <feather-icon icon="EyeIcon" />
                           </b-button>
                           <b-button
                              v-b-tooltip.hover.top="'Edit'"
                              variant="outline-dark"
                              size="sm"
                              @click="onEdit(props.row.id)"
                           >
                              <feather-icon icon="EditIcon" />
                           </b-button>
                           <b-button
                              v-b-tooltip.hover.top="'Delete'"
                              variant="outline-danger"
                              size="sm"
                              @click="onDelete(props.row.id)"
                           >
                              <feather-icon icon="TrashIcon" />
                           </b-button>
                        </b-button-group>
                     </center>
                  </span>
               </template>

               <!-- pagination -->
               <template slot="pagination-bottom" slot-scope="props">
                  <pagination-custom
                     :total="props.total"
                     :page-length="pageLength"
                     :page-options="pageOptions"
                     :page-changed="props.pageChanged"
                     :per-page-changed="props.perPageChanged"
                  />
               </template>
            </vue-good-table>
         </form>
      </b-card-code>

      <b-modal
         id="modal-form"
         size="lg"
         scrollable
         no-close-on-backdrop
         title="Form LKPM"
      >
         <div style="height: 70vh;">
            <validation-observer ref="myForm">
               <b-form>
                  <b-form-group label="Nomor" label-for="nomor">
                     <validation-provider #default="{ errors }" name="Nomor" rules="required">
                        <b-form-input
                           v-model="form.nomor"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Nama Perusahaan" label-for="nama">
                     <validation-provider
                        #default="{ errors }"
                        name="Nama Perusahaan"
                        rules="required"
                     >
                        <b-form-input
                           v-model="form.nama"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Proyek" label-for="proyek">
                     <validation-provider #default="{ errors }" name="Proyek" rules="required">
                        <b-form-textarea
                           id="proyek"
                           v-model="form.proyek"
                           rows="3"
                           max-rows="6"
                           :state="errors.length > 0 ? false : true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>

                  <b-row>
                     <b-col md="6">
                        <b-form-group label="Status" label-for="status">
                           <validation-provider
                              #default="{ errors }"
                              name="Status "
                              rules="required"
                           >
                              <b-form-select
                                 v-model="form.status"
                                 :options="options.status"
                                 :state="errors.length > 0 ? false : true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                     <b-col md="6">
                        <b-form-group label="Jenis" label-for="jenis">
                           <validation-provider
                              #default="{ errors }"
                              name="Jenis "
                              rules="required"
                           >
                              <b-form-select
                                 v-model="form.jenis"
                                 :options="options.jenis"
                                 :state="errors.length > 0 ? false : true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                  </b-row>
                  <b-row>
                     <b-col md="6">
                        <b-form-group label="Triwulan" label-for="triwulan">
                           <validation-provider
                              #default="{ errors }"
                              name="Triwulan "
                              rules="required"
                           >
                              <b-form-select
                                 v-model="form.triwulan"
                                 :options="options.triwulan"
                                 :state="errors.length > 0 ? false : true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                     <b-col md="6">
                        <b-form-group label="Tahun" label-for="tahun">
                           <validation-provider #default="{ errors }" name="Tahun" rules="required">
                              <datepicker
                                 v-model="form.tahun"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 :input-class="errors.length > 0 ? 'is-invalid form-control' : 'is-valid form-control'"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                  </b-row>

                  <b-row>
                     <b-col md="6">
                        <b-form-group label="Disubmit oleh" label-for="disubmit">
                           <validation-provider
                              #default="{ errors }"
                              name="Disubmit oleh"
                              rules="required"
                           >
                              <b-form-input
                                 v-model="form.disubmit"
                                 :state="errors.length > 0 ? false : true"
                                 type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                     <b-col md="6">
                        <b-form-group label="Tanggal Laporan" label-for="tanggal">
                           <validation-provider
                              #default="{ errors }"
                              name="Tanggal"
                              rules="required"
                           >
                              <b-form-datepicker
                                 id="tanggal"
                                 v-model="form.tanggal"
                                 class="mb-1"
                                 :state="errors.length > 0 ? false : true"
                                 locale="id"
                                 :hide-header="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                  </b-row>

                  <b-form-group label="Nilai" label-for="nilai">
                     <validation-provider #default="{ errors }" name="Nilai" rules="required">
                        <input
                           ref="nilaiInput"
                           v-model="nilai"
                           v-currency="{
                              currency: 'IDR', locale: 'id-ID', precision: 0, distractionFree: {
                                 hideNegligibleDecimalDigits: true,
                                 hideCurrencySymbol: true,
                                 hideGroupingSymbol: false
                              }
                           }"
                           class="form-control"
                           :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
               </b-form>
            </validation-observer>
         </div>
         <template #modal-footer>
            <div class="w-100">
               <b-button
                  v-b-tooltip.hover.top="'Simpan'"
                  variant="primary"
                  type="button"
                  class="float-right"
                  @click.prevent="onSave"
               >
                  <b-spinner v-if="isLoading" small />Simpan
               </b-button>
            </div>
         </template>
      </b-modal>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         hide-footer
         title="Detail"
      >
         <b-list-group>
            <b-list-group-item>
               <b>Nomor</b>
               : {{ item.nomor }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Nama Perusahan</b>
               : {{ item.nama }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Proyek</b>
               : {{ item.proyek }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Tanggal Laporan</b>
               : {{ item.tanggal | moment("DD MMMM YYYY") }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Status</b>
               :
               <b-badge variant="secondary" class="text-white">
                  <small>{{ item.status }}</small>
               </b-badge>
            </b-list-group-item>
            <b-list-group-item>
               <b>Jenis</b>
               : {{ item.jenis }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Triwulan</b>
               : {{ item.triwulan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Tahun</b>
               : {{ item.tahun }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Nilai</b>
               : {{ item.nilai | formatNumber }}
            </b-list-group-item>
         </b-list-group>
      </b-modal>

      <sweet-modal ref="modalPdf" modal-theme="light" overlay-theme="dark" width="80%">
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh;">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
